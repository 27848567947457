import React, { useState } from 'react';
import Papa from 'papaparse';
import { Box, Button, Modal, Typography } from '@mui/material'
import { ShareServiceClient, AnonymousCredential, StorageSharedKeyCredential  } from '@azure/storage-file-share';
import { AzureFileShareClient } from '@azure/storage-file-share'

const About = () => {
  const [selectedEngraving, setSelectedEngraving] = useState('');
  const [selectedFont, setSelectedFont] = useState('');
  const [selectedCupholders, setSelectedCupholders] = useState('');
  const [NumberOfCupholders, setNumberOfCupholders] = useState('');

  const [formData, setFormData] = useState({
  size: '',
  epoxyColor: '',
  leatherColor: '',
  legStyle: '',
  cupholders: '',
  numberOfCupholders: '',
  personalEngraving: '',
  font: '',
  engravingText: '',
  engraving: '',
  });

  const handleDropdownChange = (field: string, value: any) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const csv = convertFormDataToCSV(formData);
    console.log('Submitting form data:', formData, 'CSV:', csv);

    const account = 'aahyweh';
    const accountKey = 'Ts5MJa0VY6IRmQfiZH6seGlRuH3t1CijOA/841tHKIZNZwNq+FhC6lW5yZaHyUhGDVoQjtxtJXUj+AStXUddBA==';
    const fileShareName = 'woodshop';
    const fileName = 'custom_table_order.csv';

    await sendCSVToAzureFileShare(account, accountKey, fileShareName, fileName, csv);
  };
  return (
    <div>
      <h1>Custom Table Builder</h1>
      <form onSubmit={handleSubmit}>
        <Dropdown
          label="Size"
          options={['8x4', '5x3']} onSelect={(value: any) => handleDropdownChange('size', value)}        />
        <Dropdown
          label="Epoxy Color"
          options={['Blue', 'Green', 'Yellow', 'Clear']}  onSelect={(value: any) => handleDropdownChange('epoxyColor', value)}        />
        <Dropdown
          label="Leather Color"
          options={['Blue', 'Black', 'Brown', 'Green']} onSelect={(value: any) => handleDropdownChange('leatherColor', value)}       />
        <Dropdown
          label="Leg Style"
          options={['Silver Metal', 'Black Steel']}  onSelect={(value: any) => handleDropdownChange('legStyle', value)}       />
        <Dropdown
          label="Cupholders"
          options={['Yes', 'No']}
          onSelect={(value: any) => handleDropdownChange('cupholders', value)}
        />
        {formData.cupholders === 'Yes' && (
          <Dropdown
            label="Number of Cupholders"
            options={['1', '2', '3', '4', '5']}
            onSelect={(value: any) => handleDropdownChange('numberOfCupholders', value)}
          />
        )}

        <Dropdown
          label="Personal Engraving"
          options={['Yes', 'No']}
          onSelect={(value: any) => handleDropdownChange('personalEngraving', value)}
        />
        {formData.personalEngraving === 'Yes' && (
          <>
            <Dropdown
              label="Font"
              options={['Arial', 'Verdana', 'Times New Roman', 'Georgia']}
              onSelect={(value: any) => handleDropdownChange('font', value)}
            />
            <label>
              Text:
              <input type="text" name="engravingText" onChange={handleInputChange} />
            </label>
          </>
        )}

      

        <button type="submit">Submit</button>
        </form>
    </div>
  );
};



const Dropdown = ({ label, options, onSelect }) => {
  return (
    <label>
      {label}:
      <select onChange={(e) => onSelect && onSelect(e.target.value)}>
        <option value="">Select {label}</option>
        {options.map((option: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined, i: React.Key | null | undefined) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
};


// const [formData, setFormData] = useState({
//   size: '',
//   epoxyColor: '',
//   leatherColor: '',
//   legStyle: '',
//   engraving: '',
//   numberOfCupholders: '',
//   font: '',
//   engravingText: '',
// });

// const handleDropdownChange = (field, value) => {
//   setFormData((prevState) => ({ ...prevState, [field]: value }));
// };

// const handleInputChange = (e) => {
//   const { name, value } = e.target;
//   setFormData((prevState) => ({ ...prevState, [name]: value }));
// };

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   const csv = convertFormDataToCSV(formData);

//   // Add your Azure Storage Account details here.
//   const account = 'aahyweh';
//   const accountKey = 'Ts5MJa0VY6IRmQfiZH6seGlRuH3t1CijOA/841tHKIZNZwNq+FhC6lW5yZaHyUhGDVoQjtxtJXUj+AStXUddBA==';
//   const fileShareName = 'woodshop';
//   const fileName = 'custom_table_order.csv';

//   await sendCSVToAzureFileShare(account, accountKey, fileShareName, fileName, csv);
// };

// ... (rest of the App component)




// const convertFormDataToCSV = (data) => {
//   const header = Object.keys(data).join(',') + '\n';
//   const values = Object.values(data).join(',') + '\n';
//   return header + values;
// };


// const sendCSVToAzureFileShare = async (account, accountKey, fileShareName, fileName, csv) => {
//   const connectionString = `DefaultEndpointsProtocol=https;AccountName=${account};AccountKey=${accountKey};EndpointSuffix=core.windows.net`;

//   const fileShareClient = new AzureFileShareClient(connectionString);
//   const shareClient = fileShareClient.getShareClient(fileShareName);
//   const directoryClient = shareClient.getRootDirectoryClient();
//   const fileClient = directoryClient.getFileClient(fileName);

//   const contentBytes = new TextEncoder().encode(csv);
//   await fileClient.create(contentBytes.length);
//   await fileClient.uploadRange(contentBytes, 0, contentBytes.length);
// };








// const Csv = () => {
//   // ...existing useState declarations...

//   const [choices, setChoices] = useState({
//     size: '',
//     epoxyColor: '',
//     leatherColor: '',
//     legStyle: '',
//     engraving: '',
//     cupholders: '',
//     font: '',
//     engravingText: '',
//   });

//   const handleSelect = (key, value) => {
//     setChoices((prevState) => ({ ...prevState, [key]: value }));
//   };

//   const handleSubmit = async () => {
//     const csv = Papa.unparse([choices]);

//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

//     // Replace the following values with your own Azure Storage account details
//     const accountName = 'your_account_name';
//     const accountKey = 'your_account_key';

function convertFormDataToCSV(formData: { size: string; epoxyColor: string; leatherColor: string; legStyle: string; engraving: string; numberOfCupholders: string; font: string; engravingText: string; }) {
  throw new Error('Function not implemented.');
}

export default About;


// function sendCSVToAzureFileShare(account: string, accountKey: string, fileShareName: string, fileName: string, csv: void) {
//   throw new Error('Function not implemented.');
// }


// function sendCSVToAzureFileShare(account: string, accountKey: string, fileShareName: string, fileName: string, csv: void) {
//   throw new Error('Function not implemented.');
// }
const sendCSVToAzureFileShare = async (account: string, accountKey: string, fileShareName: string, fileName: string, csv: string | undefined) => {
  const sharedKeyCredential = new StorageSharedKeyCredential(account, accountKey);
  const fileShareClient = new AzureFileShareClient (
    `https://${account}.file.core.windows.net`,
    sharedKeyCredential
  );

  const shareClient = fileShareClient.getShareClient(fileShareName);
  const directoryClient = shareClient.getRootDirectoryClient();
  const fileClient = directoryClient.getFileClient(fileName);

  const contentBytes = new TextEncoder().encode(csv);
  await fileClient.create(contentBytes.length);
  await fileClient.uploadRange(contentBytes, 0, contentBytes.length);
};
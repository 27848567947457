import React, { useState, useContext } from 'react';

import { Input, Box, InputLabel, CircularProgress, Typography, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import '../sections/HeroSection.css';
import { CARDANO_EXPLORER_TESTNET, SCRIPT_ADDRESS_TESTNET } from '../config/constants'
import { WalletAPIContext } from '../context/WalletAPI/WalletAPIContext'
import { buildSendAdaToPlutusScript } from '../utils/transactions/transactionsUtils'
import { TxSendError, TxSignError } from '../types/models'
import TxErrorDialog from '../modals/TxErrorDialog'


const LotteryForm = () => {
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (number < 1 || number > 100) {
      setMessage('Please enter a number between 1 and 100');
      return;
    }

    try {
      // Replace with the actual smart contract address
      const contractAddress = '...';
      // Replace with the actual user's wallet address
      const userAddress = '...';
      // Replace with the actual Wallet API instance
      const { walletAPI, address } = useContext(WalletAPIContext)
      

      // The amount of ADA to send (10 ADA in lovelaces)
      const lovelaceAmount = '10000000';

      // Send the transaction with the user's number and required ADA
      const result = await buildSendAdaToPlutusScript(
        walletAPI,
        userAddress,
        contractAddress,
        lovelaceAmount
      );

      // Update the UI with the transaction result
      setMessage('Transaction submitted: ' + result);
    } catch (error) {
      setMessage('Error submitting transaction: ' + error.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Enter a number between 1 and 100:
          <input
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default LotteryForm;
